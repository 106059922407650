



/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

@font-face {
  font-family: Poppins-Regular;
  src: url('../fonts/poppins/Poppins-Regular.ttf'); 
}

@font-face {
  font-family: Poppins-Medium;
  src: url('../fonts/poppins/Poppins-Medium.ttf'); 
}

@font-face {
  font-family: Poppins-Bold;
  src: url('../fonts/poppins/Poppins-Bold.ttf'); 
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url('../fonts/poppins/Poppins-SemiBold.ttf'); 
}

/*//////////////////////////////////////////////////////////////////
[ RESTYLE TAG ]*/





/*---------------------------------------------*/



/*---------------------------------------------*/
input {
	outline: none;
	border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus, input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }
input:focus::-moz-placeholder { color:transparent; }
input:focus:-ms-input-placeholder { color:transparent; }

textarea:focus::-webkit-input-placeholder { color:transparent; }
textarea:focus:-moz-placeholder { color:transparent; }
textarea:focus::-moz-placeholder { color:transparent; }
textarea:focus:-ms-input-placeholder { color:transparent; }

input::-webkit-input-placeholder { 
  font-family: Poppins-Medium;  
  color: #555555;
}
input:-moz-placeholder { 
  font-family: Poppins-Medium;  
  color: #555555;
}
input::-moz-placeholder { 
  font-family: Poppins-Medium;  
  color: #555555;
}
input:-ms-input-placeholder { 
  font-family: Poppins-Medium;  
  color: #555555;
}

textarea::-webkit-input-placeholder { 
  font-family: Poppins-Medium;  
  color: #555555;
}
textarea:-moz-placeholder { 
  font-family: Poppins-Medium;  
  color: #555555;
}
textarea::-moz-placeholder { 
  font-family: Poppins-Medium;  
  color: #555555;
}
textarea:-ms-input-placeholder { 
  font-family: Poppins-Medium;  
  color: #555555;
}

/*---------------------------------------------*/
button {
	outline: none !important;
	border: none;
	background: transparent;
}

button:hover {
	cursor: pointer;
}

iframe {
	border: none !important;
}


/*---------------------------------------------*/
.container {
	max-width: 1200px;
}




/*//////////////////////////////////////////////////////////////////
[ Contact ]*/

.contactContainer {
  width: 75%;  
  padding: 15px;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: transparent;
  position: relative;
  z-index: 1;
}


.formContainer {
  border-radius: 15px;
  position: relative;
  display: visible;
}




/*==================================================================
[ Form ]*/

.formFlex {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10% 0;
}




/*------------------------------------------------------------------
[ Input ]*/

.inputWrap {
  width: 100%;
  position: relative;
  border-bottom: 2px solid #d9d9d9;
  padding-bottom: 13px;
  margin-bottom: 65px;
}

.inputWrapRes {
  width: calc((100% - 30px) / 2);
}

.inputLabel {
  font-family: Poppins-Regular;
  font-size: 15px;
  color: #ffffff;
  line-height: 1.5;
  float: left;
  
}

.inputField {
  display: block;
  width: 100%;
  background: transparent;
  font-family: Poppins-SemiBold;
  font-size: 18px;
  color: var(--color-tertiary);
  line-height: 1.2;
  
}

.inputLine {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.inputLine::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  transition: all 0.4s;
  background: var(--color-secondary);
}


/*---------------------------------------------*/
input.inputField {
  height: 40px;
  padding-left:0;
}


textarea.inputField {
  min-height: 30px;
  padding-top: 9px;
  padding-bottom: 0;
  padding-left:0;
}


.inputField:focus + .inputLine::before {
  width: 100%;
}

.has-val.input100 + .inputLine::before {
  width: 100%;
}


/*------------------------------------------------------------------
[ Button ]*/
.submitButtonContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -25px;
}

.submitButton {
  background-color: var(--color-secondary);
  color:var(--color-quaternary);

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 20px;
  min-width: 160px;
  height: 50px;
  border-radius: 25px;

  font-family: Bash;
  font-size: 16px;
  font-weight: 1000;
  letter-spacing: 0.1em;
  line-height: 1.2;

}

.submitButton:hover {
  background-color: var(--color-tertiary);
  color: var(--color-primary);
}




/*------------------------------------------------------------------
[ Responsive ]*/

@media (max-width: 992px) {
  .formContainer {
    padding: 0 80px 0 80px;
  }
}



@media (max-width: 1250px) {
  .formContainer {
    padding: 0 50px 0 50px;
  }
}

@media (min-width: 1250px) {
  .contactContainer {
    width: 50%;
    padding-top: 50px;
  }
  .formFlex{
    padding: 0;
  }

  .messageField{
    margin-top: -30px;
  }

  textarea.inputField {
    padding-bottom: 5px;
  }
}

@media (max-width: 768px) {
  .inputWrapRes {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .formContainer {
    padding: 0 15px 0 15px;
  }
}