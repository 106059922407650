svg#freepik_stories-programming:not(.animated) .animable {opacity: 0;}
svg#freepik_stories-programming.animated #freepik--Desk--inject-17 {animation: 1.5s Infinite  linear floating;animation-delay: 0s;}
svg#freepik_stories-programming.animated #freepik--Plant--inject-17 {animation: 1.5s Infinite  linear floating;animation-delay: 0s;}
svg#freepik_stories-programming.animated #freepik--Character--inject-17 {animation: 1.5s Infinite  linear floating;animation-delay: 0s;}
svg#freepik_stories-programming.animated #freepik--speech-bubble--inject-17 {animation: 1.5s Infinite  linear wind;animation-delay: 0s;}            
@keyframes 
floating {
  0% {
  opacity: 1; 
  transform: translateY(0px);}                
  50% { 
    transform: translateY(-10px);}             
  100% {
    opacity: 1;                    
    transform: translateY(0px);}}                   
  
@keyframes
wind {                
  0% {                    
    transform: rotate( 0deg );}                
  25% {                    
    transform: rotate( 1deg );}                
  75% {                   
    transform: rotate( -1deg );}            
  }        
  .animator-hidden { display: none; }